/* .App {
  text-align: center;
} */
/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%); */
  background-repeat: no-repeat;
  min-height: 100vh;
  background-image: linear-gradient(-225deg, #58e2c2 0%, #EACCF8 48%, #9185eb 100%);
}
/* 
.App-link {
  color: #61dafb;
} */


.overflow-check {
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}

.show-company {
  padding: 0 5px 0 5px;
  font-size: 11px !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-transform: capitalize;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}