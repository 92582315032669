.table-position {
  position: absolute;
  left: 2%;
  margin: 10px;
}

.option-table {
  position: absolute;
  left: 16px;
  /* margin: 6px 0px 0px -99%!important; */
  /* z-index: 88888; */
}

.option-table-two {
  position: absolute;
  right: 16px;
  /* margin: 6px 0px 0px -166px!important; */
  /* z-index: 88888; */

}

table {
  overflow: hidden;
}

.hide {
  display: none;
}

.myDIV:hover + .hide {
  display: block;
  color: red;
}
