.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .dots-container {
    width: 105px !important;
  }
  .magic-dots.slick-dots ul {
    padding: 0;
    display: flex;
    transition: all 0.2s;
    position: relative;
    margin: 0;
  }
  
  .magic-dots.slick-dots li {
    width: 7px !important;
    margin: 0px 4px 0px 4px;
  }
  
  .magic-dots.slick-dots li.slick-active {
    margin-right: 8px;
    margin-left: 10px;
  }
  
  .active-dot.active-dot {
    width: 18px;
  }
  
  .magic-dots.slick-dots li.slick-active button:before {
    color: transparent;
    width: 18px;
    background-color: #94a3b8;
    height: 6px;
    border-radius: 4px;
    font-size: 0px !important;
    top: 5.5px;
  }
  .magic-dots.slick-dots li button:before {
    transition: font-size 0.35s;
    font-size: 28px !important;
    content: "\2022";
    opacity: 1 !important;
    color: #cbd5e1;
    transition: none !important;
  }
  .magic-dots.slick-dots li.small button:before {
    font-size: 8px;
    line-height: 20px;
  }
  
  .rec.rec-arrow {
    visibility: hidden;
}

button.rec-dot{
    background-color: #A0AEC0;
    box-shadow: 0 0 1px 3px #A0AEC0;
    width: 2px;
    height: 2px;   
    border-radius: 5px;
  }
  
  button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
    box-shadow: 0 0 1px 3px #194BFB;
    background-color: #194BFB;
    width: 18px;
    /* height: 4px;   */
  }
  /* .grUxXV {
    height: 500px !important;
    width: 500px !important
  } */
/* 
  .rect{
    height: 200px !important;
    width: 100px !important
  } */

/* 
.right.carousel-control, 
.left.carousel-control 
{
     visibility:hidden;
} */